import React from "react";

import { NavLink } from "react-router-dom";

import './styles.scss';
import { routeMessages } from "pages/Messages";

const ChatItem = (props) => {

    const getLettersUsername = (text) => {
        const words = text.split(' ').filter(Boolean);
        if (words.length >= 2) {
          return words[0].charAt(0) + words[1].charAt(0);
        } else if (words.length === 1) {
          return words[0].charAt(0);
        }
        return '';
    };

    let countMessages;

    if (props.count < 1) {
        countMessages = '';
    } else if (props.count <= 9) {
        countMessages = props.count;
    } else {
        countMessages = '9+';
    }

    return (
        <div className="chats-list__item">
            <NavLink to={routeMessages(props.chatId)} className="chats-list__card f-center">
                <div className="chats-list__avatar f-center-center">
                    {props.image ? <img src={props.image} alt={props.title} /> :  <span className="chats-list__avatar-text">{getLettersUsername(props.title).toUpperCase()}</span>}
                </div>
                <div className="chats-list__content">
                    <div className="chats-list__head f-center-jcsb">
                        <p className="chats-list__title">
                            {props.title}
                        </p>
                        <p className="chats-list__date">
                            {props.date}
                        </p>
                    </div>
                    <div className="chats-list__main f-center-jcsb">
                        <p className="chats-list__message">
                            {props.last ? <span>Вы:</span> : ''} {props.message ? props.message : 'Сообщений нет'}
                        </p>
                        {countMessages ? <span className="chats-list__count f-center-center"> {countMessages} </span> : ''}
                    </div>
                </div>
            </NavLink>
        </div>
    )
}

export default ChatItem;