import React, {useState, useEffect} from "react";
import routeAuth from "./routes";

import axios from "axios-controller";

import BackNav from "components/BackNav";
import { useSelector } from "react-redux";
import Loading from "components/Loading";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { routeDeal } from "pages/Deal";

const AuthPage = () => {

    const token = useSelector((state) => state.token);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const dealId = queryParams.get('deal_id');

    const [isFocused, setIsFocused] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const handleFocus = () => {
        setIsFocused(true);
    }

    const handleBlur = () => {
        setIsFocused(false);
    }

    const [phone, setPhone] = useState(0);
    
    const handleInputChange = (e) => {
        setPhone(e.target.value);
    };

    useEffect(() => {
        const getUserPhone = async () => {
            try {
                const response = await axios.get('/api/auth/data',{
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                });
    
                setPhone(response.data["phoneNumber"])
                setIsLoading(false);
            } catch (err) {
                console.log(err);
            }
        }
        getUserPhone();
    }, []);

    return(
        <section className="auth f-center-center">
            {!isLoading && (
                <div className="container">
                    <BackNav title="Назад к договору" backPage={routeDeal(dealId)} />
                    <div className="auth-block">
                        <div className="auth-block__head">
                            <h1 className="auth-block__title">
                                Введите номер
                            </h1>
                            <p className="auth-block__text">
                                Мы отправим сообщение с кодом
                            </p>
                        </div>
                        <form action="" className={`auth-form ${isFocused ? 'focused' : ''}`}>
                            <div className="auth-form__input">
                                <input type="tel" placeholder="+7" value={phone} onChange={handleInputChange} onFocus={handleFocus} onBlur={handleBlur} required />
                                <div className="auth-form__icon">
                                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.2539 18.417C10.6172 18.417 7.58496 16.9141 4.67578 14.0225C1.75781 11.1221 0.254883 8.06348 0.254883 5.47949C0.254883 3.87988 0.703125 2.86035 1.74023 1.90234C1.81055 1.84082 1.88086 1.7793 1.95117 1.71777C2.53125 1.20801 3.12891 0.953125 3.69141 0.961914C4.35059 0.970703 4.96582 1.33105 5.49316 2.08691L7.34766 4.74121C7.92773 5.55859 7.97168 6.5166 7.2334 7.25488L6.58301 7.90527C6.35449 8.13379 6.33691 8.34473 6.5127 8.59961C6.85547 9.13574 7.49707 9.85645 8.12109 10.4805C8.74512 11.0957 9.6416 11.8867 10.1162 12.2119C10.3799 12.3877 10.5908 12.3701 10.8105 12.1416L11.4697 11.4912C12.208 10.7529 13.166 10.7969 13.9834 11.3682L16.6025 13.1875C17.3848 13.7324 17.7363 14.3301 17.7363 14.9893C17.7363 15.543 17.4814 16.1494 16.9629 16.7295C16.9014 16.8086 16.8398 16.8789 16.7783 16.9492C15.8379 17.9775 14.792 18.417 13.2539 18.417ZM13.2539 16.958C14.2031 16.9404 15.0293 16.6064 15.6094 15.9385C15.6621 15.877 15.7061 15.8154 15.7588 15.7539C15.9785 15.5078 16.084 15.2354 16.084 14.998C16.084 14.7344 15.9873 14.5146 15.7236 14.3389L13.2803 12.7217C12.9727 12.5195 12.6387 12.502 12.3486 12.8096L11.6455 13.5215C11.0039 14.1807 10.2393 14.1279 9.6416 13.6973C9 13.2314 7.91016 12.2559 7.12793 11.4824C6.38965 10.7529 5.50195 9.75098 5.02734 9.08301C4.59668 8.48535 4.54395 7.7207 5.20312 7.0791L5.91504 6.37598C6.21387 6.07715 6.20508 5.74316 6.00293 5.44434L4.35059 2.96582C4.1748 2.70215 3.94629 2.59668 3.69141 2.59668C3.44531 2.59668 3.18164 2.71094 2.93555 2.92188C2.86523 2.97461 2.8125 3.01855 2.75098 3.07129C2.08301 3.66016 1.74902 4.47754 1.73145 5.42676C1.69629 7.66797 3.34863 10.5684 5.7832 12.9766C8.20898 15.3848 11.0127 16.9932 13.2539 16.958Z" fill="#6D7780"/>
                                    </svg>
                                </div>
                            </div>
                            <button type="button" className="auth-form__btn" disabled={phone.length <= 8}>
                                Получить код
                            </button>
                        </form>
                    </div>
                </div>
            )}
            <Loading loading={isLoading} />
        </section>
    )
};

export {routeAuth};

export default AuthPage;