import React from "react";
import './styles.scss';

const Modal = (props) => {
    return (
        <>
            {props.active ? (
                <div className="modal">
                    <div className="modal-body">
                        <p className="modal-body__text">
                            {props.text}
                        </p>
                    </div>
                </div>
            ) : ('')}
        </>
    )
}

export default Modal; 