export const setToken = (token) => ({
  type: 'SET_TOKEN',
  payload: token,
});

export const setFilled = (getFilled) => ({
  type: 'SET_GET_FILLED',
  payload: getFilled,
});

export const reset = () => ({
  type: 'RESET',
});

export const setAccountAvatar = (avatar) => ({
  type: 'SET_ACC_AVATAR',
  payload: avatar,
});
export const setUserId = (useId) => ({
  type: 'SET_USER_ID',
  payload: useId,
});