import React, { useEffect, useState } from "react";
import './styles.scss';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from "axios-controller";

import { setAccountAvatar, setUserId } from "../../redux/actions";

import {routeDeals as routeDealsPage} from 'pages/Deals';
import { routeCreate } from 'pages/CreateDeal';
import { routeChats } from "pages/Chats";
import { routeAccount } from "pages/Account";

import AccountAvatar from 'assets/img/avatar.png';
import DealsBtn from "./components/DealsBtn";
import LinkBtn from "./components/LinkBtn";
import { routeDealConfirm } from "pages/DealConfirm";
import { routeDrafts } from "pages/Drafts";
import SaveBtn from "./components/SaveBtn";
import DealEditBtn from "./components/DealEditBtn";
import CreateChatBtn from "./components/CreateChatBtn";
import { routeCreateChats } from "pages/CreateChat";
import { routeWriting } from "pages/DealWriting";
// import CreateStepBtn from "./components/CreateStepBtn";

const MobileNav = (props) => {

    const token = useSelector((state) => state.token);
    const userAvatar = useSelector((state) => state.avatar);
    const userId = useSelector((state) => state.userId);
    const getFilled = useSelector((state) => state.getFilled);

    const [profileAvatar, setProfileAvatar] = useState(userAvatar);

    const dispatch = useDispatch();

    useEffect(() => {
        const getLocalParams = async () => {
    
            if (userAvatar === false || userId === false) {
                const response = await axios.get('/api/auth/data',{
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                });
                
                if (userAvatar === false) {
                    setProfileAvatar(response.data["image"]);
                    dispatch(setAccountAvatar(response.data["image"]));
                }

                if (userId === false) {
                    dispatch(setUserId(response.data["id"]));
                }
            }
        }
        getLocalParams();
    });

    // const [filled, setFilled] = useState(null);

    // useEffect(() => {
        
    //     if (getFilled) {
    //       const valueFilled = getFilled;
    //       setFilled(valueFilled);
    //     }
    // }, []);

    // const filledState = localStorage.getItem("getFilled");

    // const getFilledParse = filledState.getFilled ? 'Получили после перехода' : 'Перезагрузка сработала';

    // console.log('Переход: ' + filledState.getFilled.getFilled);
    // console.log('Перезагрузка: ' + filledState.getFilled);

    // console.log('Значение сейчас: ' + getFilledParse);

    // const getFilled = false;

    const location = useLocation();
    const currentUrl = location.pathname;

    let btnType;

    if (currentUrl === routeCreate()) {
        btnType = 'deals'
    } else if (props.drafts) {
        btnType = 'drafts'
    } else if (props.draftsDetails) {
        btnType = 'draftsDetails'
    } else if (props.draftsAdd) {
        btnType = 'draftsAdd'
    } else if (props.editPage) {
        btnType = 'dealedit'
    } else if (props.gigachat) {
        btnType = 'createChat'
    } else if (props.hidden) {
        btnType = 'hidden'
    } else {
        btnType = 'create'
    }

    return (
        <>
            {getFilled !== false ? (
                <div className="mobile-navigation">
                    <div className="mobile-navigation__wrapper">
                        <div className={`mobile-navigation__control ${props.disabledBtn ? 'disabled' : ''}`}>
                            {btnType === 'deals' ? <DealsBtn handleClick={() => props.handleClick(props.stage + 1)} btnTitle={props.btnTitle} dealData={props.dealData} activeBtn={props.activeBtn} stage={props.stage} /> : ''}
                            {btnType === 'create' ? <LinkBtn url={routeCreate()} title="Новый договор" /> : ''}
                            {btnType === 'drafts' ? <LinkBtn url={routeWriting(props.dealId)} lockedNav={props.lockedNav} title="Генерировать договор" /> : ''}
                            {btnType === 'draftsDetails' ? <SaveBtn url={routeDrafts()} title="Сохранить" /> : ''}
                            {btnType === 'draftsAdd' ? '' : ''}
                            {btnType === 'createChat' ? <CreateChatBtn url={routeCreateChats()} title="Новый чат" /> : ''}
                            {btnType === 'dealedit' ? <DealEditBtn /> : ''}
                        </div>
                        <div className="mobile-navigation-list f-center-jcsb">
                            <div className="mobile-navigation-list__item">
                                <NavLink to={routeDealsPage()} activeClassName={'active'} className="mobile-navigation-list__link">
                                    <div className="mobile-navigation-list__icon f-center-center">
                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.293 6.97461C10.7568 6.97461 10.4404 6.64062 10.4404 6.06934V5.14648C10.4404 4.5752 10.7568 4.24121 11.293 4.24121H12.0312C12.1016 3.22168 12.9541 2.39551 14 2.39551C15.0459 2.39551 15.8984 3.22168 15.96 4.24121H16.707C17.2344 4.24121 17.5596 4.5752 17.5596 5.14648V6.06934C17.5596 6.64062 17.2344 6.97461 16.707 6.97461H11.293ZM14 5.09375C14.4307 5.09375 14.7734 4.7334 14.7734 4.31152C14.7734 3.88965 14.4307 3.53809 14 3.53809C13.5693 3.53809 13.2178 3.88965 13.2178 4.31152C13.2178 4.7334 13.5693 5.09375 14 5.09375ZM9.33301 23.876C7.46973 23.876 6.47656 22.874 6.47656 20.9844V7.68652C6.47656 5.83203 7.44336 4.78613 9.25391 4.78613H9.45605C9.4209 4.90039 9.40332 5.02344 9.40332 5.14648V5.96387C9.40332 6.14844 9.42969 6.32422 9.47363 6.47363H9.28906C8.55078 6.47363 8.16406 6.95703 8.16406 7.7041V20.958C8.16406 21.749 8.59473 22.1885 9.41211 22.1885H18.5879C19.4053 22.1885 19.8359 21.749 19.8359 20.958V7.7041C19.8359 6.95703 19.4492 6.47363 18.7021 6.47363H18.5176C18.5703 6.32422 18.5879 6.14844 18.5879 5.96387V5.14648C18.5879 5.02344 18.5791 4.90039 18.5439 4.78613H18.7461C20.5566 4.78613 21.5146 5.83203 21.5146 7.68652V20.9844C21.5146 22.874 20.5303 23.876 18.6582 23.876H9.33301Z" fill="#6D7780"/>
                                        </svg>
                                    </div>
                                    <div className="mobile-navigation-list__text">
                                        Договоры
                                    </div>
                                </NavLink>
                            </div>
                            <div className="mobile-navigation-list__item">
                                <NavLink to={routeChats()} activeClassName={'active'} className="mobile-navigation-list__link">
                                    <div className="mobile-navigation-list__icon f-center-center">
                                        <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.30371 23.085C7.2666 23.085 6.88867 22.1621 7.44238 21.4238C7.82031 20.9229 8.49707 19.9385 8.875 19.2178C6.12402 17.8818 4.32227 15.2979 4.32227 12.415C4.32227 7.87988 8.84863 4.23242 14.5 4.23242C20.1514 4.23242 24.6777 7.87988 24.6777 12.415C24.6777 17.1084 20.0459 20.6943 13.8496 20.5801C11.9951 21.8984 9.52539 23.085 8.30371 23.085ZM9.34961 21.3008C10.1406 20.9844 11.7139 19.9912 12.7686 19.209C13.1201 18.9365 13.4014 18.8223 13.7793 18.8223C14.0957 18.8311 14.333 18.8398 14.5 18.8398C19.1846 18.8398 22.9111 15.957 22.9111 12.415C22.9111 8.87305 19.1846 5.99023 14.5 5.99023C9.81543 5.99023 6.08887 8.87305 6.08887 12.415C6.08887 14.7178 7.64453 16.7217 10.3252 17.9873C10.8877 18.251 10.9844 18.6729 10.7207 19.1562C10.3955 19.7627 9.72754 20.6328 9.2793 21.1953C9.20898 21.2832 9.25293 21.3447 9.34961 21.3008Z" fill="#6D7780"/>
                                        </svg>
                                    </div>
                                    <div className="mobile-navigation-list__text">
                                        Чаты
                                    </div>
                                </NavLink>
                            </div>
                            <div className="mobile-navigation-list__item">
                                <NavLink to={routeAccount()} activeClassName={'active'} className="mobile-navigation-list__link">
                                    <div className="mobile-navigation-list__icon f-center-center">
                                        {userAvatar !== false && userAvatar !== null ? <img src={userAvatar} alt="" /> : <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.00879 8.68945C5.88184 8.68945 4.15918 6.82617 4.15918 4.53223C4.15918 2.27344 5.89062 0.445312 8.00879 0.445312C10.1357 0.445312 11.8496 2.24707 11.8496 4.51465C11.8496 6.81738 10.127 8.68945 8.00879 8.68945ZM8.00879 7.16895C9.23047 7.16895 10.2588 6.01758 10.2588 4.51465C10.2588 3.05566 9.23926 1.96582 8.00879 1.96582C6.77832 1.96582 5.75 3.07324 5.75 4.53223C5.75 6.02637 6.78711 7.16895 8.00879 7.16895ZM2.52441 16.8633C1.00391 16.8633 0.274414 16.3799 0.274414 15.3516C0.274414 12.9521 3.28027 9.71777 8 9.71777C12.7197 9.71777 15.7256 12.9521 15.7256 15.3516C15.7256 16.3799 14.9961 16.8633 13.4844 16.8633H2.52441ZM2.22559 15.3428H13.7744C13.9766 15.3428 14.0645 15.2812 14.0645 15.1143C14.0645 13.7695 11.8848 11.2383 8 11.2383C4.11523 11.2383 1.94434 13.7695 1.94434 15.1143C1.94434 15.2812 2.02344 15.3428 2.22559 15.3428Z" fill="#6D7780"/>
                                        </svg>}
                                    </div>
                                    <div className="mobile-navigation-list__text">
                                        Аккаунт
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            ) : ('')}
        </>
    )
}

export default MobileNav;