import React, {useState, useEffect} from "react";
import routeProfile from "./routes";
import axios from 'axios-controller';

import MobileNav from "components/MobileNav";
import Loading from "components/Loading";

import DefaultAvatar from 'assets/img/default-user.png';

import { useSelector } from "react-redux";
import { NavLink, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { routeDeal } from "pages/Deal";

const ProfilePage = () => {

    const token = useSelector((state) => state.token);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const dealId = queryParams.get('deal_id');

    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(true);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [avatar, setAvatar] = useState();

    useEffect(() => {

        const getUserData = async () => {
            try {

                const response = await axios.get(`/api/users/${id}`,{
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                });

                setFirstName(response.data["firstName"]);
                setLastName(response.data["lastName"]);
                setMiddleName(response.data["middleName"]);
                setAvatar(response.data["image"]);

                setIsLoading(false);

                console.log(response.data);

            } catch (error) {
                console.error('POST Err', error);
            }
        };
        getUserData();

    }, []);

    return(
        <section className="account">
            {!isLoading ? (
                <form className="account-form">
                    <div className="container">
                        <div className="account-block f-center-center">
                            {avatar ? (
                                <div className="account-block__offer">
                                    <div className="account-block__avatar">
                                        <img src={avatar} alt="" />
                                    </div>
                                </div>
                            ) : (
                                <div className="account-block__offer">
                                    <div className="account-block__avatar">
                                        <img src={DefaultAvatar} alt="" />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="accout-settings">
                            <div className="account-settings__item">
                                <div className="account-settings__head">
                                    <h2 className="account-settings__title">
                                        Основные данные
                                    </h2>
                                </div>
                                <div className="account-settings__wrap">
                                    <div className="account-settings__input">
                                        <p className="account-settings__label">
                                            Имя
                                        </p>
                                        <div className="account-settings__input">
                                            <input type="text" name="name" value={firstName} readOnly required />
                                        </div>
                                    </div>
                                    <div className="account-settings__input">
                                        <p className="account-settings__label">
                                            Фамилия
                                        </p>
                                        <div className="account-settings__input">
                                            <input type="text" name="surname" value={lastName} readOnly required />
                                        </div>
                                    </div>
                                    <div className="account-settings__input">
                                        <p className="account-settings__label">
                                            Отчество
                                        </p>
                                        <div className="account-settings__input">
                                            <input type="text" name="middleName" value={middleName} readOnly required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <NavLink to={routeDeal(dealId)} className="page-save">
                            Вернуться назад
                        </NavLink>
                    </div>
                </form>
            ) : ('')}
            <MobileNav login={true} />
            <Loading loading={isLoading} />
        </section>
    )
};

export {routeProfile};

export default ProfilePage;