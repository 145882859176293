import React, { useState, useEffect } from 'react';

// libs
import {Route, Switch, Redirect } from 'react-router-dom';
// import CryptoJS from 'crypto-js';
// import axios from 'axios-controller';

// routePages
import MainPage, {routeMain as routeMainPage} from 'pages/MainPage';
import DealsPage, {routeDeals as routeDealsPage} from 'pages/Deals';
import AuthPage, {routeAuth as routeAuthPage} from 'pages/Auth';
import ChatsPage, {routeChats as routeChatsPage} from 'pages/Chats';
import CreateChatsPage, {routeCreateChats as routeCreateChatsPage} from 'pages/CreateChat';
import MessagesPage, {routeMessages as routeMessagesPage} from 'pages/Messages';
import CreatePage, {routeCreate as routeCreatePage} from 'pages/CreateDeal';
import AccountPage, {routeAccount as routeAccountPage} from 'pages/Account';
import DraftsPage, {routeDrafts as routeDraftsPage} from 'pages/Drafts';
import StepsDetailsPage, {routeStepsDetails as routeStepsDetailsPage} from 'pages/StepsEdit';
import StepsAddPage, {routeStepsAdd as routeStepsAddPage} from 'pages/StepsAdd';
import DealConfirmPage, {routeDealConfirm as routeDealConfirmPage} from 'pages/DealConfirm';
import DealPage, {routeDeal as routeDealPage} from 'pages/Deal';
import InitialPage, {routeInit as routeInitPage} from 'pages/InitialPage';
import ProfilePage, {routeProfile as routeProfilePage} from 'pages/ProfilePage';
import WritingPage, {routeWriting as routeWritingPage} from 'pages/DealWriting';

const AppContent = () => {

  // const [telegramUserId, setTelegramUserId] = useState("622089839");

  // const [isLoading, setIsLoading] = useState(true);

  // const [acceptAccount, setAcceptAccount] = useState(null);

  // const key = CryptoJS.enc.Hex.parse("f34f852aefd041203a567c7f7083688371b7bdac3a3b2fe981c1de2d89983993");
  // const iv = CryptoJS.enc.Hex.parse("db5592c547ed2cc70621d4a1a8ed9ec2");

  // // const [responseData, setResponseData] = useState(null);

  // useEffect(() => {

  //   const signInRequest = async (hash) => {
  //     try {
  //       const response = await axios.post('/api/auth/signin', {
  //         telegramID: hash
  //       }, {
  //         headers: {
  //           'accept': 'application/json',
  //           'Content-Type': 'application/json',
  //         },
  //       });

  //       // setResponseData(response.data);

  //       setAcceptAccount(response.data["filled"]);

  //       setIsLoading(false);

  //       // console.log(response.data["filled"]);
  //     } catch (error) {
  //       console.error('POST Err', error);
  //     }
  //   };

  //   const encryptText = async  () => {
  //     const encrypted = CryptoJS.AES.encrypt(telegramUserId, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });

  //     await signInRequest(encrypted.toString());
  //   };

  //   encryptText();
  // }, []);

  // if (!isLoading) {
  //   console.log(acceptAccount);
  //   if (acceptAccount === false) {
  //     return <Redirect 
  //     to={{
  //         pathname: routeAccountPage()
  //     }}
  // />
  //   } else {
  //     return <Redirect 
  //     to={{
  //         pathname: routeDealsPage()
  //     }}
  // />
  //   }
  // }

  return (
    <div className="main-wrapper">
      <main className='main-container'>
        <Switch>
            <Route exact path={routeInitPage()} component={InitialPage} />
            <Route exact path={routeMainPage()} component={MainPage} />
            <Route exact path={routeDealsPage()} component={DealsPage} />
            <Route exact path={routeAuthPage()} component={AuthPage} />
            <Route exact path={routeChatsPage()} component={ChatsPage} />
            <Route exact path={routeCreateChatsPage()} component={CreateChatsPage} />
            <Route exact path={routeMessagesPage()} component={MessagesPage} />
            <Route exact path={routeCreatePage()} component={CreatePage} />
            <Route exact path={routeAccountPage()} component={AccountPage} />
            <Route exact path={routeDraftsPage()} component={DraftsPage} />
            <Route exact path={routeStepsDetailsPage()} component={StepsDetailsPage} />
            <Route exact path={routeStepsAddPage()} component={StepsAddPage} />
            <Route exact path={routeDealConfirmPage()} component={DealConfirmPage} />
            <Route exact path={routeDealPage()} component={DealPage} />
            <Route exact path={routeProfilePage()} component={ProfilePage} />
            <Route exact path={routeWritingPage()} component={WritingPage} />
            <Redirect 
                to={{
                    pathname: routeDealsPage()
                }}
            />
        </Switch>
      </main>
    </div>
  );
}

export default AppContent;
