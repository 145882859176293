import React, { useEffect, useState } from "react";
import routeCreate from "./routes";
import { NavLink } from "react-router-dom";
import MobileNav from "components/MobileNav";
import BackNav from "components/BackNav";
import CreateStart from "components/CreateStart";
import CreateStages from "components/CreateStages";
import DealWriting from "components/CreateStages/compontents/DealWriting";
import CreateMembers from "components/CreateMembers";
import routeMain from "pages/Auth/routes";
import { routeDeals } from "pages/Deals";

const CreatePage = () => {

    // const token = localStorage.getItem("token");

    const [createStage, setCreateStage] = useState(1);

    const handleStage = (index) => {
        setCreateStage(index);
    }

    const [disabledBtn, setDisabledBtn] = useState(false);

    let backNavTitle,
    activeBtn,
    btnTitle

    const [paramsDeal, setParamsDeal] = useState({});

    const handleParamsDeal = (params) => {
      setParamsDeal(params);
    };

    if (createStage === 1) {
        backNavTitle = 'Новый договор';
        activeBtn = false;
        btnTitle = 'Создать новый';
    } else if (createStage === 2) {
        backNavTitle = 'Создание договора';
        activeBtn = false;
        btnTitle = 'Далее';
    } else if (createStage === 3) {
        backNavTitle = 'Создание договора';
        activeBtn = true;
        btnTitle = 'Составить договор';
    } 
    // else if (createStage === 4) {
    //     backNavTitle = 'Создание договора';
    //     activeBtn = true;
    //     btnTitle = 'Далее';
    // }

    useEffect(() => {
        if (createStage === 1) {
            setDisabledBtn(false);
        } else if (createStage === 2) {
            setDisabledBtn(true);
            if (paramsDeal.dealTitle && paramsDeal.dealTitle.length >= 3 && paramsDeal.dealDescription && paramsDeal.dealDescription.length >= 3) {
                setDisabledBtn(false);
            }
        }
    }, [paramsDeal, createStage]);

    console.log('Result: ' + JSON.stringify(paramsDeal));

    return(
        <section className="create">
            <div className="container">
                <div className="create-inner">
                    <BackNav title={backNavTitle} backPage={routeDeals()}  handleClick={handleStage} stage={createStage} />
                    {createStage === 1 ? <CreateStart /> : ''}
                    {createStage === 2 ? <CreateStages paramsDeal={handleParamsDeal} /> : ''}
                    {createStage === 3 ? <CreateMembers dealData={paramsDeal} paramsDeal={handleParamsDeal} /> : ''}
                    {/* {createStage === 4 ? <DealWriting dealData={paramsDeal} /> : ''} */}
                </div>
            </div>
            <MobileNav login={false} handleClick={handleStage} btnTitle={btnTitle} disabledBtn={disabledBtn} activeBtn={activeBtn} dealData={paramsDeal} stage={createStage} />
        </section>
    )
}

export {routeCreate};

export default CreatePage;