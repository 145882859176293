const initialState = {
  token: null,
  getFilled: false,
  avatar: false,
  userId: false
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TOKEN':
      return {
        ...state,
        token: action.payload,
      };
    case 'SET_ACC_AVATAR':
      return {
        ...state,
        avatar: action.payload,
      };
    case 'SET_GET_FILLED':
      return {
        ...state,
        getFilled: action.payload,
      };
    case 'SET_USER_ID':
        return {
          ...state,
          userId: action.payload,
        };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};

export default rootReducer;