import React, {useState, useEffect} from "react";
import routeCreateChats from "./routes";
import axios from "axios-controller";

import MobileNav from "components/MobileNav";
import BackNav from "components/BackNav";
import { routeChats } from "pages/Chats";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const CreateChatsPage = () => {

    const token = useSelector((state) => state.token);

    const [chatName, setChatName] = useState("");

    const [createdChat, setCreatedChat] = useState(false);

    const [chatUrl, setChatUrl] = useState("");

    const [createdProccess, setCreatedProccess] = useState(false);

    const handleChange = (e) => {
        setChatName(e.target.value);
    }

    const createChat = async (e) => {
        e.preventDefault();

        setCreatedProccess(true);

        const chatData = {
            title: chatName,
            image: "none"
        }

        try {
            const response = await axios.post('/api/gigachat/dialog/create', chatData, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });

            // console.log(response.data);

            setChatName("");

            const createChatUrl = "/chats/" + response.data.id;

            setChatUrl(createChatUrl);

            setCreatedChat(true);

        } catch (err) {
            console.log('Create Err: ' + err);
        }
    }

    if (createdChat) {
        return <Redirect to={chatUrl} />;
    }

    return(
        <section className="chats">
            <div className="container">
                <BackNav title={'Создать чат'} backPage={routeChats()} />
                <div className="create-chats">
                    <form className={`create-chats__item ${createdProccess ? "disabled" : ""}`} onSubmit={createChat}>
                        <h2 className="create-chats__title">
                            Название
                        </h2>
                        <div className="create-chats__input">
                            <input type="text" name="chat_name" placeholder="Ввести название" onChange={handleChange} required />
                        </div>
                        <button type="submit" className="create-chats__btn">
                            {createdProccess ? "Создание чата ..." : "Создать чат"}
                        </button>
                    </form>
                </div>
            </div>
            <MobileNav login={true} hidden={true} />
        </section>
    )
};

export {routeCreateChats};

export default CreateChatsPage;