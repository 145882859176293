import React, {useState, useEffect} from "react";
import routeMain from "./routes";
import MobileNav from "components/MobileNav";

const MainPage = () => {


    return(
        <section className="main">
            <div className="container f-center-center">
                <div className="main-block">
                    <div className="main-block__logo f-center-center">
                        <svg width="89" height="89" viewBox="0 0 89 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="44.5" cy="44.5" r="11.995" stroke="#0180FF" stroke-width="0.01"/>
                            <circle cx="44.5" cy="44.5" r="22" stroke="#0180FF" stroke-width="4"/>
                            <circle cx="44.5" cy="44.5" r="34" stroke="#0180FF" stroke-width="4"/>
                        </svg>
                    </div>
                    <h1 className="main-block__title">
                        Юрик
                    </h1>
                    <p className="main-block__text">
                        Здесь будут ваши договоры
                    </p>
                </div>
            </div>
            <MobileNav login={true} />
        </section>
    )
};

export {routeMain};

export default MainPage;