import React, { useEffect, useState } from "react";
import routeWriting from "./routes";

import axios from "axios-controller";

import Loading from "components/Loading";

import { useSelector } from "react-redux";
import MobileNav from "components/MobileNav";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";



const DealWriting = () => {

    const { id } = useParams();

    const token = useSelector((state) => state.token);

    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState("");

    const [dealText, setDealText] = useState("");

    useEffect(() => {
        // Порядчик должен сделать ремонт балкона, включая окраску стен.
        const dealAgreement = async (dealTitle, dealText) => {

            setIsLoading(true);
            setLoadingText("Ожидайте, договор создается…");

            const dealData = {
                // text: props.dealData.dealDescription
            }
            // props.dealData.dataDealId
            // const dealId = props.dealData.dataDealId;

            try {
                const res = await axios.put(`/api/agreement/update/${0}`, dealData, {
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                });

                console.log(res.data);

                setDealText(res.data.text);

                setIsLoading(false);
                setLoadingText("");
            } catch (err) {
                console.log('Write Err: ' + err);
            }
        }

        const getDeal = async () => {
            try {


                const response = await axios.get(`/api/agreement/${id}`, {
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                });

                console.log(response.data);

                setIsLoading(false);

            } catch (error) {
                console.error('GET Err', error);
            }
        };
        getDeal();

        // dealAgreement();
    }, []);

    return (
        <div className="create-writing">
            {!isLoading ? (
                <textarea>{dealText}</textarea>
                // <div className="create-writing__content" dangerouslySetInnerHTML={{ __html: dealText }}>

                // </div>
            ) : ''}
            <div className="create-writing__work f-center-center">
                <p className="create-writing__work-title">
                    Юрик
                </p>
                <div className="create-writing__work-logo">
                    <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="22.5" cy="22" r="5.995" stroke="#0180FF" stroke-width="0.01"/>
                        <circle cx="22.5" cy="22" r="11" stroke="#0180FF" stroke-width="2"/>
                        <circle cx="22.5" cy="22" r="17" stroke="#0180FF" stroke-width="2"/>
                    </svg>
                </div>
                <p className="create-writing__work-text">
                    Работаю
                </p>
            </div>
            <MobileNav writing={true} confirmId={id} />
            <Loading loading={isLoading} loadingText={loadingText} />
        </div>
    )
}

export {routeWriting};

export default DealWriting;