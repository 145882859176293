import React from "react";

import { NavLink } from "react-router-dom";

const SaveBtn = (props) => {
    return (
        <NavLink to={props.url} className="mobile-navigation__create f-center-center">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.85938 17.2578C8.55208 17.2578 8.28125 17.1068 8.04688 16.8047L3.99219 11.6406C3.91406 11.5469 3.85677 11.4531 3.82031 11.3594C3.78385 11.2604 3.76562 11.1641 3.76562 11.0703C3.76562 10.8568 3.83594 10.6823 3.97656 10.5469C4.11719 10.4062 4.29688 10.3359 4.51562 10.3359C4.77604 10.3359 5 10.4635 5.1875 10.7188L8.82812 15.4844L15.8438 4.30469C15.9375 4.15885 16.0339 4.05729 16.1328 4C16.2318 3.9375 16.3594 3.90625 16.5156 3.90625C16.7292 3.90625 16.901 3.97135 17.0312 4.10156C17.1615 4.23177 17.2266 4.40365 17.2266 4.61719C17.2266 4.71094 17.2109 4.80469 17.1797 4.89844C17.1484 4.98698 17.099 5.08594 17.0312 5.19531L9.64844 16.8203C9.45573 17.112 9.19271 17.2578 8.85938 17.2578Z" fill="#0180FF"/>
            </svg>
            {props.title}
        </NavLink>
    )
}

export default SaveBtn;