import React from "react";
import './styles.scss';

const Loading = (props) => {
    return (
        <>
            {props.loading ? (
                    <div className="loading">
                        <div className="loading-block">
                            <div class="loader">
                                
                            </div>
                            {props.loadingText ? (
                                <div className="loading-block__text">
                                    {props.loadingText}
                                </div>
                            ) : ''}
                        </div>
                    </div>
            ) : ('')}
        </>
    )
}

export default Loading; 