import React from "react";

import { NavLink } from "react-router-dom";

const DealEditBtn = (props) => {
    return (
        <div className="mobile-navigation__edit f-center-jcsb">
            <button type="button" className="mobile-navigation__create mobile-navigation__create_delete f-center-center">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.25781 17.5625C4.4401 17.5625 3.82552 17.3594 3.41406 16.9531C3.00781 16.5521 2.80469 15.9479 2.80469 15.1406V5.60156C2.80469 4.79427 3.00781 4.1901 3.41406 3.78906C3.82552 3.38281 4.4401 3.17969 5.25781 3.17969H14.7344C15.5521 3.17969 16.1641 3.38281 16.5703 3.78906C16.9818 4.1901 17.1875 4.79427 17.1875 5.60156V15.1406C17.1875 15.9479 16.9818 16.5521 16.5703 16.9531C16.1641 17.3594 15.5521 17.5625 14.7344 17.5625H5.25781ZM5.27344 16.3047H14.7188C15.1042 16.3047 15.401 16.2031 15.6094 16C15.8229 15.7917 15.9297 15.4844 15.9297 15.0781V5.66406C15.9297 5.25781 15.8229 4.95312 15.6094 4.75C15.401 4.54167 15.1042 4.4375 14.7188 4.4375H5.27344C4.88281 4.4375 4.58333 4.54167 4.375 4.75C4.16667 4.95312 4.0625 5.25781 4.0625 5.66406V15.0781C4.0625 15.4844 4.16667 15.7917 4.375 16C4.58333 16.2031 4.88281 16.3047 5.27344 16.3047ZM7.33594 13.6641C7.16406 13.6641 7.01562 13.6016 6.89062 13.4766C6.77083 13.3516 6.71094 13.2031 6.71094 13.0312C6.71094 12.8594 6.77344 12.7135 6.89844 12.5938L9.11719 10.3672L6.89844 8.14062C6.77344 8.02083 6.71094 7.875 6.71094 7.70312C6.71094 7.53125 6.77083 7.38542 6.89062 7.26562C7.01562 7.14583 7.16406 7.08594 7.33594 7.08594C7.51302 7.08594 7.65885 7.14844 7.77344 7.27344L10 9.49219L12.25 7.26562C12.375 7.13542 12.5182 7.07031 12.6797 7.07031C12.8516 7.07031 12.9974 7.13281 13.1172 7.25781C13.2422 7.3776 13.3047 7.52344 13.3047 7.69531C13.3047 7.86198 13.2422 8.00781 13.1172 8.13281L10.8906 10.3672L13.1094 12.5859C13.2344 12.7109 13.2969 12.8594 13.2969 13.0312C13.2969 13.2031 13.2344 13.3516 13.1094 13.4766C12.9896 13.6016 12.8438 13.6641 12.6719 13.6641C12.5 13.6641 12.3516 13.599 12.2266 13.4688L10 11.25L7.78906 13.4688C7.66927 13.599 7.51823 13.6641 7.33594 13.6641Z" fill="#F2220F"/>
                </svg>
                Расторгнуть
            </button>
            <button type="button" className="mobile-navigation__create f-center-center">
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.85938 17.2578C8.55208 17.2578 8.28125 17.1068 8.04688 16.8047L3.99219 11.6406C3.91406 11.5469 3.85677 11.4531 3.82031 11.3594C3.78385 11.2604 3.76562 11.1641 3.76562 11.0703C3.76562 10.8568 3.83594 10.6823 3.97656 10.5469C4.11719 10.4062 4.29688 10.3359 4.51562 10.3359C4.77604 10.3359 5 10.4635 5.1875 10.7188L8.82812 15.4844L15.8438 4.30469C15.9375 4.15885 16.0339 4.05729 16.1328 4C16.2318 3.9375 16.3594 3.90625 16.5156 3.90625C16.7292 3.90625 16.901 3.97135 17.0312 4.10156C17.1615 4.23177 17.2266 4.40365 17.2266 4.61719C17.2266 4.71094 17.2109 4.80469 17.1797 4.89844C17.1484 4.98698 17.099 5.08594 17.0312 5.19531L9.64844 16.8203C9.45573 17.112 9.19271 17.2578 8.85938 17.2578Z" fill="#0180FF"/>
                </svg>
                Сохранить
            </button>
        </div>
    )
}

export default DealEditBtn;